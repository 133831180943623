import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Spinner,
  DropdownItem
} from "reactstrap";
import { DebounceInput } from "react-debounce-input";

import "../../../node_modules/@icon/ionicons/ionicons.css";

import { FiSearch, FiSettings, FiLogOut } from "react-icons/fi";
import { FaUserAlt } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { MdApps, MdClose } from "react-icons/md";
import { GoGear } from "react-icons/go";
import history from "~/services/history";
import {
  logoutRequest,
  alterarCartorio as alterarCartorioAction
} from "~/store/modules/auth/actions";
import {
  debounceFiltro,
  limparBuscar,
  addImoveisFiltrados
} from "~/store/modules/mosaico/actions";
import { virtualModulo } from "~/store/modules/virtualCart/actions";
import { modulosAdmin } from "./modulosAdmin";
import { validarTermoEhCoordenada } from "~/utils/validarTermoEhCoordenada";
import "./style.scss";
import "../../../node_modules/bootstrap/js/dist/tooltip";

const PageNavbar = () => {
  $(function() {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const dispatch = useDispatch();
  const modulosIndisponiveis = [
    "clientes",
    "chat",
    "controle_processos",
    "mosaico"
  ];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [toggleMenuLateral, setToggleMenuLateral] = useState(true);
  const [dropdownOpenCartorios, setDropdownOpenCartorios] = useState(false);
  const [dropdownOpenMenu, setDropdownOpenMenu] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const toggleCartorios = () =>
    setDropdownOpenCartorios(prevState => !prevState);
  const toggleMenu = () => setDropdownOpenMenu(prevStateMenu => !prevStateMenu);

  const handleLogout = () => {
    dispatch(logoutRequest(false));
  };

  const { nome, admin, foto } = useSelector(state => state.auth.usuario);

  const { cartorios, foto_valida } = useSelector(state => state.auth);

  const API_VIRTUAL = process.env.REACT_APP_URL_API_VIRTUAL_CART;

  let { cns, nome_fantasia, cor } = useSelector(state => state.auth.cartorio);
  const cns_formatado = cns.replace(/(\d{2})(\d{3})(\d{1})/, "$1.$2-$3");

  let cor_cartorio_atual = cor;

  if (cor === "bluegray") {
    cor_cartorio_atual = "#6699cc";
  } else if (cor === "padrao") {
    cor_cartorio_atual = "#90d7a3";
  }

  let { especialidades } = useSelector(state => state.auth);

  const { termo, loading_filtro } = useSelector(state => state.mosaico);

  const [busca, setBusca] = useState("");

  useEffect(() => {
    setBusca(termo);
  }, [termo]);

  useEffect(() => {
    if (termo.length > 0) {
      mostrarFormBusca();
      setTimeout(() => {
        $("#navbar_search_btn").click();
      }, 10);
    } else {
      setTimeout(() => {
        fecharBuscaForm();
      }, 10);
      fecharBuscaForm();
    }
  }, [termo]);

  const mostrarFormBusca = () => {
    let $wrapper = $(".hk-wrapper");
    $("html,body").animate({ scrollTop: 0 }, "slow");
    $("#mosaico-alterar-imoveis").animate({ top: "115px" });
    $(".navbar-search input").focus();
    $wrapper.addClass("navbar-search-toggle");
    $(window).trigger("resize");
  };

  const fecharBuscaForm = () => {
    let $wrapper = $(".hk-wrapper");
    $wrapper.removeClass("navbar-search-toggle");
    $("#mosaico-alterar-imoveis").animate({ top: "65px" });
    $(window).trigger("resize");
  };

  const MenuLateral = () => {
    let $wrapper = $(".hk-wrapper");
    if (toggleMenuLateral) {
      $wrapper.addClass("hk-nav-toggle");
    } else {
      $wrapper.removeClass("hk-nav-toggle");
      $(window).trigger("resize");
    }
  };

  const pesquisarMosaicos = ({ target }) => {
    setBusca(target.value);
    const coordenadaValida = validarTermoEhCoordenada(target.value);
    const numeroValido =
      target.value.length >= 2 && parseFloat(target.value) > 0;

    if (coordenadaValida) {
      dispatch(debounceFiltro(target.value));
      return false;
    }
    if (!coordenadaValida && numeroValido) {
      dispatch(debounceFiltro(target.value));
      return false;
    }

    if (target.value === "") {
      dispatch(debounceFiltro(""));
    }
  };

  const exibirPerfil = () => {
    const img = foto_valida ? `${API_VIRTUAL}arquivos/${foto}` : false;
    if (img) {
      return (
        <img
          src={img}
          alt="foto-usuário"
          className="avatar-img rounded-circle"
        />
      );
    }
    return (
      <div
        className="rounded-circle"
        style={{
          background: "orange",
          width: "35px",
          textAlign: "center"
        }}
      >
        <span
          style={{
            position: "absolute",
            fontSize: "25px",
            fontWeight: "bold",
            left: "9px",
            top: "-9px"
          }}
        >
          {nome[0]}
        </span>
      </div>
    );
  };

  const criarModulos = modulos => {
    return modulos.map((modulo, index) => {
      if (!modulosIndisponiveis.includes(modulo.nome)) {
        return (
          <DropdownItem
            key={index}
            className="col-md-4 col-sm-4 col-6 mb-10 ml-0 mosaico-item-apps"
          >
            <div
              className="mosaico-link"
              onClick={e => {
                e.preventDefault();
                dispatch(virtualModulo(modulo.nome));
              }}
            >
              <div className="mosaico-display gallery-img">
                <img
                  // eslint-disable-next-line prefer-template
                  src={require("~/assets/icons/" + modulo.nome + ".png")}
                  alt="foto-sistema-trello"
                  className="avatar-img rounded-circle"
                />
                {modulo.titulo}
              </div>
            </div>
          </DropdownItem>
        );
      }
      return null;
    });
  };

  const renderModulos = () => {
    if (admin === "S") {
      return criarModulos(modulosAdmin);
    }
    return criarModulos(especialidades);
  };

  const alterarCartorio = id => {
    dispatch(alterarCartorioAction(id));
  };

  return (
    <>
      <nav className="navbar navbar-expand-xl navbar-light fixed-top hk-navbar">
        <a
          id="navbar_toggle_btn"
          className="navbar-toggle-btn nav-link-hover"
          href="mapa"
          onClick={e => {
            e.preventDefault();
            setToggleMenuLateral(!toggleMenuLateral);
            MenuLateral();
          }}
        >
          <i className="ion ion-ios-menu" />
        </a>
        <Dropdown
          isOpen={dropdownOpenCartorios}
          toggle={toggleCartorios}
          style={{ border: `3px solid ${cor_cartorio_atual}`, padding: "10px" }}
        >
          <DropdownToggle
            tag="span"
            data-toggle="dropdown"
            aria-expanded={dropdownOpenCartorios}
            className="cursor-pointer"
          >
            <div
              id="navbar_toggle_btn"
              className="navbar-toggle cursor-pointer"
            >
              {nome_fantasia} - CNS {cns_formatado}
              {cartorios.length > 1 && <IoIosArrowDown className="ml-1" />}
            </div>
          </DropdownToggle>
          <div className="tab-content">
            <div className="tab-pane fade show active" role="tabpanel">
              <div className="row hk-gallery mosaico-centro">
                <DropdownMenu
                  right
                  className="mosaico-dropdown-menu-apps animated flipInX mosaico-item-cartorios"
                >
                  {cartorios.map(cartorio => {
                    if (cartorio.cns !== cns) {
                      let cor_atual = cartorio.cor;

                      if (cartorio.cor === "bluegray") {
                        cor_atual = "#6699cc";
                      } else if (cartorio.cor === "padrao") {
                        cor_atual = "#90d7a3";
                      }
                      return (
                        <DropdownItem
                          key={cartorio.cns}
                          className="mb-10 mosaico-dropdown-cartorio"
                          onClick={() => {
                            alterarCartorio(cartorio.id);
                          }}
                        >
                          <div>
                            <span
                              className="mosaico-item-cartorios-bullet"
                              style={{
                                backgroundColor: cor_atual
                              }}
                            />{" "}
                            <span>
                              {cartorio.nome_fantasia
                                ? cartorio.nome_fantasia + " - "
                                : ""}
                              CNS{" "}
                              {cartorio.cns.replace(
                                /(\d{2})(\d{3})(\d{1})/,
                                "$1.$2-$3"
                              )}
                            </span>
                          </div>
                        </DropdownItem>
                      );
                    }
                    return false;
                  })}
                </DropdownMenu>
              </div>
            </div>
          </div>
        </Dropdown>
        <ul className="navbar-nav hk-navbar-content">
          <li className="nav-item">
            <div
              id="navbar_search_btn"
              className="nav-link cursor-pointer"
              onClick={() => {
                mostrarFormBusca();
              }}
            >
              <FiSearch />
            </div>
          </li>
          <Link to="/config" className="mr-3">
            <li className="nav-item">
              <div id="navbar_gear_btn" className="nav-link cursor-pointer">
                <GoGear />
              </div>
            </li>
          </Link>
          <li className="nav-item">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
                className="cursor-pointer"
              >
                <MdApps />
              </DropdownToggle>
              <DropdownMenu
                right
                className="mosaico-dropdown-menu-apps mosaico-modulos-cartorios animated flipInX"
              >
                <div className="tab-content">
                  <div className="tab-pane fade show active" role="tabpanel">
                    <div className="row hk-gallery mosaico-centro">
                      <DropdownItem className="col-md-4 col-sm-4 col-6 mb-10 mosaico-item-apps">
                        <div
                          onClick={e => {
                            e.preventDefault();
                            dispatch(virtualModulo(""));
                          }}
                          className="mosaico-link"
                        >
                          <div className="mosaico-display gallery-img">
                            <img
                              src={require("~/assets/icons/trello.png")}
                              alt="foto-sistema-trello"
                              className="avatar-img rounded-circle"
                            />
                            Início
                          </div>
                        </div>
                      </DropdownItem>
                      {renderModulos()}
                      <DropdownItem className="col-md-4 col-sm-4 col-6 mb-10 mosaico-item-apps">
                        <div
                          className="mosaico-link"
                          onClick={e => {
                            e.preventDefault();
                            dispatch(virtualModulo("administracao"));
                          }}
                        >
                          <div className="mosaico-display gallery-img">
                            <img
                              src={require("~/assets/icons/admin.png")}
                              alt="foto-sistema-admin"
                              className="avatar-img rounded-circle"
                            />
                            Admin
                          </div>
                        </div>
                      </DropdownItem>
                    </div>
                  </div>
                </div>
              </DropdownMenu>
            </Dropdown>
          </li>
          <li className="nav-item dropdown dropdown-authentication">
            <Dropdown isOpen={dropdownOpenMenu} toggle={toggleMenu}>
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpenMenu}
                className="cursor-pointer"
              >
                <div className="nav-link dropdown-toggle no-caret">
                  <div className="media">
                    <div className="media-img-wrap">
                      <div className="avatar">{exibirPerfil()}</div>
                    </div>
                    <div className="media-body">
                      <span>
                        {nome}
                        <IoIosArrowDown className="ml-1" />
                      </span>
                    </div>
                  </div>
                </div>
              </DropdownToggle>
              <DropdownMenu className="mosaico-menu-opcoes animated flipInX">
                <DropdownItem className="mosaico-menu-opcoes-item">
                  <div
                    className="dropdown-item"
                    onClick={e => {
                      e.preventDefault();
                      dispatch(virtualModulo("editar-perfil"));
                    }}
                  >
                    <FaUserAlt className="mosaico-icones-menu" />
                    <span>Editar Perfil</span>
                  </div>
                </DropdownItem>
                <DropdownItem className="mosaico-menu-opcoes-item">
                  <div
                    className="dropdown-item"
                    onClick={e => {
                      e.preventDefault();
                      dispatch(virtualModulo("editar-serventia"));
                    }}
                  >
                    <FiSettings className="mosaico-icones-menu" />
                    <span>Editar Perfil da Serventia</span>
                  </div>
                </DropdownItem>
                <DropdownItem className="mosaico-menu-opcoes-item">
                  <Link to="/" className="dropdown-item" onClick={handleLogout}>
                    <FiLogOut className="mosaico-icones-menu" />
                    <span>Sair</span>
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>
      </nav>
      <form
        role="search"
        className="navbar-search"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <div className="position-relative p-0">
          <FiSearch className="navbar-search-icon" />
          <DebounceInput
            type="text"
            name="busca"
            className="form-control"
            minLength={2}
            debounceTimeout={1000}
            placeholder={`Pesquisar por: SNCR(CCIR), Matrícula e Coordenadas (lat, long) ex.: -11.62069, -47.961939 ou 10° 8' 13.921" S 48° 20' 23.438" W`}
            value={busca}
            onChange={e => {
              pesquisarMosaicos(e);
            }}
          />
          <div className="mr-5">
            {loading_filtro && (
              <a
                id=""
                href="mapa"
                onClick={e => e.preventDefault()}
                className="navbar-search-close mr-50"
              >
                <Spinner />
              </a>
            )}
          </div>
          <div
            onClick={() => {
              dispatch(limparBuscar(busca, true));
              dispatch(addImoveisFiltrados(false));
              setBusca("");
              fecharBuscaForm();
            }}
          >
            <a
              id="navbar_search_close"
              href="mapa"
              onClick={e => e.preventDefault()}
              className="navbar-search-close mr-10"
            >
              <MdClose />
            </a>
          </div>
        </div>
      </form>
    </>
  );
};

export default PageNavbar;
