import produce from "immer";

const initialStatte = {
  municipios_poligonos: [],
  listaMunicipios: [],
  circuscricoes: [],
  loading: false,
};

export default function municipio(state = initialStatte, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@municipio/ADD_MUNICIPIOS": {
        draft.municipios_poligonos = action.payload.poligonos;
        break;
      }
      case "@municipio/ADD_LISTA_MUNICIPIOS": {
        draft.listaMunicipios = action.payload.lista;
        break;
      }
      case "@municipio/ADD_CIRCUSCRICOES": {
        draft.circuscricoes = action.payload.dados;
        break;
      }
      case "@acervo/ENVIAR_ELEMENTOS": {
        draft.loading = true;
        break;
      }
      case "@municipio/PARAR_LOADING": {
        draft.loading = false;
        break;
      }
      case "@auth/ALTERAR_CARTORIO": {
        draft.municipios_poligonos = [];
        draft.listaMunicipios = [];
        draft.circuscricoes = [];
        break;
      }
      default:
    }
  });
}
