import { all, call, takeLatest, put, delay } from "redux-saga/effects";

import { estadoInput } from "@nato-digital/nato-components/build/store/matricula/actions";
import { store } from "~/store";
import api from "~/services/api";
import apiAcervo from "~/services/apiAcervo";
import { validarTermoEhCoordenada } from "~/utils/validarTermoEhCoordenada";
import history from "~/services/history";

import { addToast } from "~/store/modules/toast/actions";
import { logoutRequest } from "~/store/modules/auth/actions";
import { addCoordenadasTeste } from "~/store/modules/comparar/actions";

import { verificarMatricula as verificarMatriculaSaga } from "~/store/modules/acervo/sagas";

import {
  adicionarDados,
  adicionarPlanta,
  filtrarMosaicos,
  adiconarDocumentos,
  limparDocumentos,
  buscarDocumentos,
  adicionarNome,
  addImovelBuscado,
  abrirArquivo,
  statusLoading,
  statusLoadingFiltro,
  statusLoadingMapa,
  imovelTemSobreposicao,
  novaSituacao,
  addImoveisFiltrados,
  buscarPlanta as buscarPlantaAction,
  buscarPoligonos as buscarPoligonosAction,
  atualizarPoligonosStatusMemorial,
  imovelPopUpMapa,
  adicionarDadosMapa,
} from "./actions";

export function* buscarPoligonos({ payload }) {
  const { pagina, limite } = payload;

  yield put(statusLoadingMapa(true));
  const response = yield call(
    api,
    "get",
    `listagem?page=${pagina}&limite=${limite}`
  );
  const { data, status } = response;
  switch (status) {
    case 200:
      const { dados } = data;
      const {
        poligonos,
        atual,
        ultima,
        atualizado_em,
        conflitos,
        total,
      } = dados;

      yield put(
        adicionarDados(
          poligonos,
          atual,
          ultima,
          atualizado_em,
          conflitos,
          total
        )
      );
      yield put(statusLoadingMapa());
      break;

    case 400:
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000,
        })
      );
      yield put(logoutRequest());
      break;

    case 403:
      break;

    case 404:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
          duracao: 10000,
        })
      );
      break;

    default:
      history.push("/");
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* mapaPoligonos({ payload }) {
  const response = yield call(api, "get", `mapas`);
  console.log(store.getState().auth.cartorio.cns);
  const { data, status } = response;
  switch (status) {
    case 200:
      const { dados } = data;
      const { poligonos, atualizado_em, conflitos } = dados;

      yield put(statusLoading());
      yield put(adicionarDadosMapa(poligonos, atualizado_em, conflitos));
      break;

    case 400:
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000,
        })
      );
      yield put(logoutRequest());
      yield put(statusLoadingMapa());
      break;

    case 403:
      break;

    case 404:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
          duracao: 10000,
        })
      );
      yield put(statusLoadingMapa());
      break;

    default:
      history.push("/");
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
  yield put(statusLoadingMapa());
}

export function* buscarPlanta({ payload }) {
  const { id, documentos } = payload;
  const response = yield call(api, "get", `detalhe/${id}`);

  const { data, status } = response;
  const { dados } = data;
  switch (status) {
    case 200:
      const { poligono, centro } = dados;

      yield put(
        adicionarPlanta(
          poligono[0],
          centro || { lat: -10.278144, lng: -48.257082 }
        )
      );

      if (documentos) {
        yield put(buscarDocumentos(poligono[0].propriedades.matricula_atual));
      }
      break;

    case 400:
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000,
        })
      );
      yield put(logoutRequest());
      break;

    case 403:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    case 404:
      history.push("/");
      yield put(adicionarPlanta([], { lat: -10.4304, lng: -48.31946 }));

      yield put(
        addToast({
          titulo: data.erro,
          texto: "Tente outro item",
          tipo: "warning",
        })
      );
      break;

    default:
      history.push("/");
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* filtrarMosaicosRequest({ payload }) {
  const { termo } = payload;
  yield put(statusLoadingFiltro(true));
  yield put(addImoveisFiltrados(false));
  yield delay(1000);

  if (termo.length == 0) {
    return false;
  }
  const coordenadaValida = validarTermoEhCoordenada(termo);

  if (coordenadaValida) {
    history.push("/mapa");
    return false;
  }

  const response = yield call(api, "get", `filtrar/${termo}`);
  const { data, status } = response;
  switch (status) {
    case 200:
      yield put(statusLoadingFiltro());
      for (const imovel of data.dados) {
        if (!imovel.tem_coordenadas) {
          yield put(
            addToast({
              titulo: "Imóvel encontrado não possui coordenadas.",
              tipo: "warning",
              duracao: 10000,
            })
          );
          history.push(`/detalhe/${imovel.id}`);
          return false;
        }
      }

      const lista_imoveis_filtrados_id = data.dados.map((item) => item.id);

      history.push("/mapa");
      yield put(addImoveisFiltrados(lista_imoveis_filtrados_id));

      break;

    case 400:
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi finalizada",
          tipo: "warning",
          duracao: 10000,
        })
      );
      yield put(addImoveisFiltrados(false));
      yield put(logoutRequest());
      break;

    case 403:
      yield put(addImoveisFiltrados(false));
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    case 404:
      yield put(addImoveisFiltrados(false));
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    default:
      yield put(addImoveisFiltrados(false));
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
  yield put(statusLoadingFiltro());
}

export function* debounceFiltrarMosaicos({ payload }) {
  yield put(filtrarMosaicos(payload.termo));
}

export function* buscarDocumentosAcervo({ payload }) {
  const { matricula, abrir_aba } = payload;
  if (!matricula || matricula == 0) {
    return false;
  }
  const response = yield call(
    apiAcervo,
    "get",
    `arquivos-mosaico/${matricula}`
  );

  const { data, status } = response;
  const { dados } = data;

  switch (status) {
    case 200:
      let aux = 0;

      const matricula_recente = dados.filter((item) => {
        if (item.id > aux && item.nome.endsWith(matricula)) {
          aux = item.id;
          return true;
        }
      });

      yield put(adiconarDocumentos(matricula_recente));

      if (abrir_aba) {
        for (let item of dados) {
          if (item.nome.endsWith(matricula)) {
            yield put(abrirArquivo(item.id));
          }
        }
      }
      break;

    case 404:
      yield put(limparDocumentos());
      break;

    default:
      yield put(limparDocumentos());
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* abrirDocumento({ payload }) {
  const { id } = payload;

  const aba = window.open();
  aba.document.write("carregando...");

  const response = yield call(apiAcervo, "get", `/${id}`);

  const { data, status } = response;

  switch (status) {
    case 200:
      const { url } = data;

      aba.location.href = url;

      break;

    case 403:
      break;

    case 404:
      aba.close();
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    default:
      aba.close();
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* atualizarImovel({ payload }) {
  const {
    id,
    matricula_atual,
    nome,
    detentor,
    cpfcnpj,
    sncr,
    area,
    matricula_ordem,
    categoria_imovel,
    rua,
    numero,
    logradouro,
    bairro,
    complemento,
    data_de_abertura,
  } = payload;

  const responseAcervo = yield call(verificarMatriculaSaga, {
    payload: { matricula_atual },
  });
  const { planta } = store.getState().mosaico;
  if (!responseAcervo) {
    yield put(estadoInput(true, "danger"));
    return false;
  }
  let data_abertura_formatada;
  if (data_de_abertura) {
    let dataArray = data_de_abertura.split("/");
    data_abertura_formatada = new Date(
      +dataArray[2],
      dataArray[1] - 1,
      +dataArray[0]
    );
  }

  const response = yield call(api, "put", `atualizar/${id}`, {
    matricula_atual,
    nome,
    detentor,
    cpfcnpj,
    sncr,
    area,
    matricula: matricula_ordem,
    detentores: planta.detentores,
    categoria_imovel,
    rua,
    numero,
    logradouro,
    bairro,
    complemento,
    data_de_abertura: data_abertura_formatada,
  });

  const { data, status } = response;
  const { dados } = data;

  switch (status) {
    case 200:
      yield put(adicionarNome(detentor));
      yield put(
        addToast({
          titulo: "Dados atualizados com sucesso",
          tipo: "success",
          duracao: 10000,
        })
      );
      history.push(`/lista`);
      // window.location.reload(true);
      break;

    case 403:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    case 404:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    case 422:
      yield put(
        addToast({
          titulo: data.erro,
          texto: dados,
          tipo: "warning",
          duracao: 10000,
        })
      );
      break;

    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* buscarSncr({ payload }) {
  yield delay(500);
  const { termo } = payload;
  const response = yield call(api, "get", `sncr/${termo}`);

  const { data, status } = response;

  switch (status) {
    case 200:
      const { dados } = data;
      yield put(addImovelBuscado(dados));
      break;

    case 400:
    case 401:
    case 403:
    case 404:
      break;

    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* mesclarImoveis({ payload }) {
  const { imovel_id, imovel_mesclar_id } = payload;

  const response = yield call(api, "post", "mesclar", {
    imovel_id,
    imovel_mesclar_id,
  });

  const { status } = response;

  switch (status) {
    case 200:
      history.push("/lista");
      window.location.reload(true);
      break;

    case 404:
    case 422:
      break;

    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* validarKmlSobreposicao({ payload }) {
  yield put(imovelTemSobreposicao(true));

  const { id_imovel, kml, matricula_atual } = payload;
  const kml_de_teste = new File(
    [kml],
    id_imovel + matricula_atual + new Date().getTime() + kml.name,
    { type: kml.type }
  );

  const pasta_oculta = "mosaico";
  const formData = new FormData();
  formData.append("arquivos[]", kml_de_teste);
  formData.append("pastas[]", ".mosaico");
  formData.append("oculto", true);
  const response = yield call(
    apiAcervo,
    "post",
    `relacionar/${pasta_oculta}`,
    formData
  );

  const { status, data } = response;
  switch (status) {
    case 200:
      const arquivo_kml = data.dados.filter((arquivo) => {
        if (arquivo.extensao === "kml") {
          return arquivo;
        }
      });

      if (kml) {
        const responseArquivo = yield call(
          apiAcervo,
          "get",
          `/${arquivo_kml[0].id}`
        );

        const responseValidarSopreposicao = yield call(
          api,
          "post",
          `coordenadas/${id_imovel}`,
          {
            url: responseArquivo.data.url,
          }
        );
        if (responseValidarSopreposicao.status === 200) {
          yield put(
            imovelTemSobreposicao(
              responseValidarSopreposicao.data.dados
                .tem_sobreposicao_ou_fora_de_municipio
            )
          );
          yield put(
            addCoordenadasTeste(
              responseValidarSopreposicao.data.dados.coordenadas
            )
          );
        } else if (responseValidarSopreposicao.status === 403) {
          yield put(
            addToast({
              titulo: responseValidarSopreposicao.data.erro,
              tipo: "warning",
              duracao: 3000,
            })
          );
        } else if (responseValidarSopreposicao.status === 404) {
          yield put(
            addToast({
              titulo: responseValidarSopreposicao.data.erro,
              tipo: "warning",
              duracao: 3000,
            })
          );
        } else if (responseValidarSopreposicao.status === 422) {
          yield put(
            addToast({
              titulo: responseValidarSopreposicao.data.erro,
              tipo: "warning",
              duracao: 3000,
            })
          );
        } else {
          yield put(
            addToast({
              titulo: "Arquivo KML não é válido",
              texto: "Verifique este arquivo antes de enviar",
              tipo: "warning",
              duracao: 3000,
            })
          );
        }
      }

      break;
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000,
        })
      );
      yield put(logoutRequest());
      break;
    case 404:
      break;
    case 422:
      break;
    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
  return false;
}

export function* situacaoImovel({ payload }) {
  const { id, qr_code } = payload;

  const response = yield call(api, "put", `situacao/${id}`, {
    qr_code,
  });

  const { data, status } = response;

  switch (status) {
    case 200:
      const { dados } = data;
      yield put(novaSituacao());

      if (dados.situacao != null) yield put(buscarPlantaAction(id));

      if (dados.situacao === "Cancelada") {
        yield put(
          addToast({
            titulo: `Situação do imóvel: ${dados.situacao}.`,
            tipo: "success",
          })
        );
      }
      if (dados.situacao !== "Cancelada" && dados.situacao !== null) {
        yield put(
          addToast({
            titulo: `Situação do imóvel: ${dados.situacao}.`,
            tipo: "success",
          })
        );
      }

      if (dados.situacao == null) {
        yield put(
          addToast({
            titulo: `Ocorreu algum problema no SIGEF.`,
            texto: "Tente novamente mais tarde",
            tipo: "warning",
          })
        );
      }
      break;

    case 403:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* removerImovel({ payload }) {
  const { id } = payload;

  const response = yield call(api, "delete", `remover/${id}`);

  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(
        addToast({
          titulo: `Imóvel removido com sucesso.`,
          tipo: "success",
        })
      );
      yield put(buscarPoligonosAction());
      break;

    case 403:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* gerarMemorial({ payload }) {
  const { imovel_id } = payload;

  const response = yield call(api, "get", `memorial/${imovel_id}`);

  const { data, status } = response;

  switch (status) {
    case 200:
      const aba = window.open();
      aba.document.write("carregando...");
      aba.location.href = data.dados.url;
      yield put(
        addToast({
          titulo: "Memorial criado com sucesso.",
          tipo: "success",
        })
      );
      break;

    case 403:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;
    case 404:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* situacaoImovelMemorial({ payload }) {
  const { id, qr_code } = payload;

  const response = yield call(api, "post", `memorial/${id}`, {
    qr_code,
  });

  const { data, status } = response;

  switch (status) {
    case 200:
      yield put(
        addToast({
          titulo: `Memorial está sendo confeccionado. Essa tarefa pode demorar, pois depende de consulta junto ao SIGEF/INCRA. Um alerta será apresentado quando concluída a geração do memorial.`,
          tipo: "success",
        })
      );
      const { poligonos } = store.getState().mosaico;
      const imovel_att_processando = poligonos.map((imovel) => {
        if (imovel.propriedades.id === id) {
          const imovel_att = {
            coordenadas: imovel.coordenadas,
            detentores: imovel.detentores,
            endereco: imovel.endereco,
            propriedades: {
              ...imovel.propriedades,
              status_memorial: "PROCESSANDO",
            },
          };
          return imovel_att;
        } else {
          return imovel;
        }
      });
      yield put(atualizarPoligonosStatusMemorial(imovel_att_processando));
      break;

    case 403:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* buscarPlantaClick({ payload }) {
  const { lista } = payload;

  if (lista.length === 0) {
    yield put(imovelPopUpMapa([]));
    return false;
  }

  const response = yield call(
    api,
    "get",
    `detalhes/`,
    {},
    { lista: lista.join(",") }
  );

  const { data, status } = response;
  const { dados } = data;
  switch (status) {
    case 200:
      const { poligonos } = dados;
      yield put(imovelPopUpMapa(poligonos));
      break;

    case 400:
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000,
        })
      );
      yield put(logoutRequest());
      break;

    case 403:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    case 404:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning",
        })
      );
      break;

    default:
      history.push("/");
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export default all([
  takeLatest("@mosaico/BUSCAR_POLIGONOS", buscarPoligonos),
  takeLatest("@mosaico/MAPA_POLIGONOS", mapaPoligonos),
  takeLatest("@mosaico/BUSCAR_PLANTA", buscarPlanta),
  takeLatest("@mosaico/DEBOUNCE_FILTRAR_MOSAICOS", debounceFiltrarMosaicos),
  takeLatest("@mosaico/FILTRAR_MOSAICOS", filtrarMosaicosRequest),
  takeLatest("@mosaico/BUSCAR_DOCUMENTOS", buscarDocumentosAcervo),
  takeLatest("@mosaico/ABRIR_DOCUMENTO", abrirDocumento),
  takeLatest("@mosaico/ATUALIZAR_IMOVEL", atualizarImovel),
  takeLatest("@mosaico/BUSCAR_SNCR", buscarSncr),
  takeLatest("@mosaico/MESCLAR_IMOVEL", mesclarImoveis),
  takeLatest("@mosaico/VALIDAR_KML_SOBREPOSICAO", validarKmlSobreposicao),
  takeLatest("@mosaico/BUSCAR_SITUACAO", situacaoImovel),
  takeLatest("@mosaico/BUSCAR_SITUACAO_MEMORIAL", situacaoImovelMemorial),
  takeLatest("@mosaico/REMOVER_IMOVEL", removerImovel),
  takeLatest("@mosaico/GERAR_MEMORIAL", gerarMemorial),
  takeLatest("@mosaico/BUSCAR_PLANTA_PARA_CLICK", buscarPlantaClick),
]);
