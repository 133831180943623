// import React from "react";
import { all, call, takeLatest, put, delay } from "redux-saga/effects";

import {
  showMatriculaPopUp,
  removeMatriculaPopUp,
  estadoInput,
} from "@nato-digital/nato-components/build/store/matricula/actions";
import { store } from "~/store";
import api from "~/services/api";
import apiAcervo from "~/services/apiAcervo";
import history from "~/services/history";
import { logoutRequest } from "~/store/modules/auth/actions";
import { matriculaExiste } from "./actions";
import { imovelTemSobreposicao, abrirArquivo } from "~/store/modules/mosaico/actions";
import { addToast } from "~/store/modules/toast/actions";
import {
  alterarLoading,
  addDadosDeComparacao,
} from "~/store/modules/comparar/actions";

export function* enviarArquivos({ payload }) {
  const { id_imovel, kml, rinex, matricula_atual } = payload;
  const pasta_oculta = "mosaico";

  const formData = new FormData();
  if (kml) {
    const extensao_kml = kml.name.split(".").pop();
    const novo_arquivo_nome_kml = `${new Date().getTime()}-kml.${extensao_kml}`;
    formData.append("arquivos[]", kml, novo_arquivo_nome_kml);
  } else {
    formData.append("arquivos[]", kml);
  }

  if (rinex) {
    const extensao_rinex = rinex.name.split(".").pop();
    const novo_arquivo_nome_rinex = `${new Date().getTime()}-rinex.${extensao_rinex}`;
    formData.append("arquivos[]", rinex, novo_arquivo_nome_rinex);
  } else {
    formData.append("arquivos[]", rinex);
  }

  formData.append("matricula", matricula_atual);
  formData.append("pastas[]", ".mosaico");
  formData.append("oculto", true);
  formData.append("imovel_id", id_imovel);
  yield put(imovelTemSobreposicao(false));
  const response = yield call(
    apiAcervo,
    "post",
    `relacionar/${pasta_oculta}`,
    formData
  );

  const { status, data } = response;

  switch (status) {
    case 200:
      const arquivo_kml = data.dados.filter((arquivo) => {
        if (arquivo.extensao === "kml") {
          return arquivo;
        }
      });

      if (kml) {
        const responseArquivo = yield call(
          apiAcervo,
          "get",
          `/${arquivo_kml[0].id}`
        );

        const responseAttCoordenadas = yield call(
          api,
          "put",
          `coordenadas/${id_imovel}`,
          {
            url: responseArquivo.data.url,
          }
        );

        if (responseAttCoordenadas.status === 200) {
          yield put(
            addToast({
              titulo: "Coordenadas atualizadas com sucesso.",
              tipo: "success",
              duracao: 3000,
            })
          );
        } else if (responseAttCoordenadas.status === 403) {
          yield put(
            addToast({
              titulo: responseAttCoordenadas.data.erro,
              tipo: "warning",
              duracao: 3000,
            })
          );
        } else if (responseAttCoordenadas.status === 404) {
          yield put(
            addToast({
              titulo: responseAttCoordenadas.data.erro,
              tipo: "warning",
              duracao: 3000,
            })
          );
        } else if (responseAttCoordenadas.status === 422) {
          yield put(
            addToast({
              titulo: responseAttCoordenadas.data.erro,
              tipo: "warning",
              duracao: 3000,
            })
          );
        } else {
          yield put(
            addToast({
              titulo: "Arquivo KML não é válido",
              texto: "Verifique este arquivo antes de enviar",
              tipo: "warning",
              duracao: 3000,
            })
          );
        }
      }

      break;
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000,
        })
      );
      yield put(logoutRequest());
      break;
    case 404:
      const { erro } = data;
      yield put(
        addToast({
          titulo: erro,
          texto: "Verifique a matrícula e envie o arquivo novamente",
          tipo: "warning",
          duracao: 10000,
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          titulo: data.erro,
          texto: "Verifique os arquivos",
          tipo: "warning",
          duracao: 10000,
        })
      );
      break;
    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* verificarMatricula({ payload }) {
  const { matricula_atual } = payload;
  yield delay(1500);

  if (matricula_atual.length <= 1) {
    return false;
  }

  const response = yield call(apiAcervo, "get", `mosaico/${matricula_atual}`);

  const { data, status } = response;

  switch (status) {
    case 200:
      const { matricula, permissao } = data.dados;
      yield put(matriculaExiste(matricula, permissao, matricula_atual));
      if (!permissao) {
        yield put(estadoInput(true, "danger"));
        yield put(
          addToast({
            titulo: "Seu usuário não tem permissão para criar mátricula.",
            texto: "Entre em contato com o suporte.",
            tipo: "warning",
            duracao: 10000,
          })
        );
        return false;
      }
      if (!matricula) {
        yield put(estadoInput(true, "danger"));
        yield put(showMatriculaPopUp(matricula_atual));
        return false;
      }
      yield put(estadoInput(true, "success"));
      return true;

      break;

    case 400:
    case 401:
    case 403:
    case 404:
      yield put(matriculaExiste(false));
      const { erro } = data;
      yield put(showMatriculaPopUp(matricula_atual));
      yield put(estadoInput(true, "danger"));
      yield put(
        addToast({
          titulo: erro,
          tipo: "warning",
          duracao: 10000,
        })
      );
      break;

    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );
  }
}

export function* criarMatricula({ payload }) {
  const { documento, matricula } = payload;

  const formData = new FormData();

  const extensao_documento = documento.name.split(".").pop();
  const novo_arquivo_nome_documento = `${new Date().getTime()}-matricula.${extensao_documento}`;

  formData.append("arquivo", documento, novo_arquivo_nome_documento);
  const responseAcervo = yield call(
    apiAcervo,
    "post",
    "/upload/registro-de-imoveis/livro-02-registro-geral",
    formData
  );
  if (responseAcervo.status === 200) {
    const acervo_id = responseAcervo.data.dados.id;
    const response = yield call(
      apiAcervo,
      "put",
      `livros-do-acervo/${acervo_id}`,
      {
        id: acervo_id,
        cartorio_id: store.getState().auth.cartorio.id,
        extensao: "pdf",
        mime: "application/pdf",
        titulo: `IMOVEIS_LV2_M-${matricula}`,
        metadados: null,
        tituloEditado: {
          certidao: false,
          certidao_data: "",
          livro: "2",
          matricula,
          modulo: "imoveis",
          pasta: ["Registro de Imóveis", "Livro 2 - Registro Geral"],
        },
        tipo: "M",
        tipo_numero: matricula,
      }
    );
    const { status } = response;

    switch (status) {
      case 200:
        yield put(estadoInput(true, "success"));
        yield put(removeMatriculaPopUp(false));
        addToast({
          titulo: "Matrícula criada com sucesso",
          tipo: "success",
        });
        break;
      default:
        yield put(
          addToast({
            titulo: "Aconteceu algum problema no servidor",
            texto: "Tente novamente mais tarde",
            tipo: "danger",
          })
        );
    }
  } else if (responseAcervo.status === 422) {
    yield put(
      addToast({
        titulo: responseAcervo.data.erro,
        texto: "Entre em contato com o suporte",
        tipo: "warning",
      })
    );
  } else {
    yield put(
      addToast({
        titulo:
          responseAcervo.data.message || "Aconteceu algum problema no servidor",
        texto: responseAcervo.data.message
          ? "Entre em contato com o suporte para criar as pastas corretas no 'Meu Acervo'"
          : "Tente novamente mais tarde",
        tipo: "danger",
      })
    );
  }
}

export function* analisarKML({ payload }) {
  const { kml } = payload;
  const pasta_oculta = "mosaico";
  const formData = new FormData();

  const extensao_kml = kml.name.split(".").pop();
  const novo_arquivo_nome_kml = `${new Date().getTime()}-comparar_kml.${extensao_kml}`;

  formData.append("arquivos[]", kml, novo_arquivo_nome_kml);
  formData.append("pastas[]", ".mosaico");
  formData.append("oculto", true);
  yield put(imovelTemSobreposicao(false));
  if (window.location.href !== "/comparar") {
    history.push("/comparar");
    yield delay(500);
  }

  const response = yield call(
    apiAcervo,
    "post",
    `relacionar/${pasta_oculta}`,
    formData
  );

  const { status, data } = response;
  switch (status) {
    case 200:
      const arquivo_kml = data.dados.filter((arquivo) => {
        if (arquivo.extensao.toLocaleLowerCase() === "kml") {
          return arquivo;
        }
      });

      if (arquivo_kml.length == 0) {
        yield put(
          addToast({
            titulo:
              "Ocorreu algum problema com o arquivo, entre em contato com o suporte.",
            tipo: "warning",
            duracao: 3000,
          })
        );
        yield put(alterarLoading(false));
        yield put(addDadosDeComparacao([]));
        return false;
      }

      if (kml) {
        const responseArquivo = yield call(
          apiAcervo,
          "get",
          `/${arquivo_kml[0].id}`
        );

        const responseBuscarKml = yield call(api, "post", `buscarKml/`, {
          url: responseArquivo.data.url,
        });

        if (responseBuscarKml.status === 200) {
          yield put(addDadosDeComparacao(responseBuscarKml.data.dados));
          yield put(
            addToast({
              titulo: "Arquivo comparado e verificado com sucesso.",
              tipo: "success",
            })
          );
        } else if (responseBuscarKml.status === 403) {
          yield put(
            addToast({
              titulo: responseBuscarKml.data.erro,
              tipo: "warning",
            })
          );
          break;
        } else if (responseBuscarKml.status === 404) {
          yield put(
            addToast({
              titulo: responseBuscarKml.data.erro,
              tipo: "warning",
            })
          );
          break;
        } else {
          yield put(
            addToast({
              titulo: "Arquivo KML não é válido",
              texto: "Verifique este arquivo antes de enviar",
              tipo: "warning",
              duracao: 3000,
            })
          );
        }
      }

      break;
    case 401:
      yield put(
        addToast({
          titulo: "Sua sessão foi expirada",
          tipo: "warning",
          duracao: 10000,
        })
      );
      yield put(logoutRequest());
      break;
    case 404:
      const { erro } = data;
      yield put(addDadosDeComparacao([]));
      yield put(
        addToast({
          titulo: erro,
          texto: "Verifique a matrícula e envie o arquivo novamente",
          tipo: "warning",
          duracao: 10000,
        })
      );
      break;
    case 422:
      yield put(
        addToast({
          titulo: data.erro,
          texto: "Verifique os arquivos",
          tipo: "warning",
          duracao: 10000,
        })
      );
      break;
    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger",
        })
      );

      yield put(addDadosDeComparacao([]));
  }
  yield put(alterarLoading(false));
}

export function* enviarElementos({ payload }) {
  const { arquivos } = payload;
  let lista_elementos = [];
  // eslint-disable-next-line no-restricted-syntax

  yield put(
    addToast({
      titulo: "Os arquivos estão sendo enviados, aguarde.",
      tipo: "success",
      duracao: 15000,
    })
  );

  for (const arquivo of arquivos) {
    const pasta_oculta = "mosaico";
    const formData = new FormData();

    const extensao = arquivo.file.name.split(".").pop();
    const novo_arquivo_nome = `${new Date().getTime()}.${extensao}`;

    formData.append("arquivos[]", arquivo.file, novo_arquivo_nome);
    formData.append("pastas[]", ".mosaico");
    formData.append("oculto", true);

    const response = yield call(
      apiAcervo,
      "post",
      `relacionar/${pasta_oculta}`,
      formData
    );

    const { status, data } = response;

    switch (status) {
      case 200:
        lista_elementos.push({
          acervo_local: data.dados[0].local,
          elemento_id: arquivo.id,
        });
        break;
      case 422:
        yield put(
          addToast({
            titulo: data.erro,
            texto: "Verifique os arquivos",
            tipo: "warning",
            duracao: 10000,
          })
        );
        break;
      default:
        yield put(
          addToast({
            titulo: "Aconteceu algum problema no servidor",
            texto: "Tente novamente mais tarde",
            tipo: "danger",
          })
        );
    }
  }

  if (lista_elementos.length > 0) {
    const responseMosaico = yield call(api, "post", `circuscricoes/`, {
      arquivos: lista_elementos,
    });

    const { status, data } = responseMosaico;

    switch (status) {
      case 200:
        yield put(
          addToast({
            titulo: "Arquivos enviados com sucesso.",
            texto: "Em breve serão analisados.",
            tipo: "success",
          })
        );
        break;
      case 403:
        yield put(
          addToast({
            titulo: data.erro,
            texto: "Verifique os arquivos",
            tipo: "warning",
          })
        );
        break;
      case 422:
        yield put(
          addToast({
            titulo: data.erro,
            texto: "Verifique os arquivos",
            tipo: "warning",
          })
        );
        break;

      default:
        yield put(
          addToast({
            titulo: "Aconteceu algum problema no servidor",
            texto: "Tente novamente mais tarde",
            tipo: "danger",
          })
        );
    }
  }
}

export function* baixarElemento({ payload }) {
  const { local } = payload;

  const response = yield call(apiAcervo, "post", `elemento/`, { local });

  const { data, status } = response;

  switch (status) {
    case 200:
      const { dados } = data;
      yield put (abrirArquivo(dados.id))
      break;

    case 403:
    case 404:
      yield put(
        addToast({
          titulo: data.erro,
          tipo: "warning"
        })
      );
      break;

    default:
      yield put(
        addToast({
          titulo: "Aconteceu algum problema no servidor",
          texto: "Tente novamente mais tarde",
          tipo: "danger"
        })
      );
  }
}

export default all([
  takeLatest("@acervo/ENVIAR_ARQUIVOS", enviarArquivos),
  takeLatest("@acervo/ENVIAR_ELEMENTOS", enviarElementos),
  takeLatest("@acervo/BAIXAR_ELEMENTOS", baixarElemento),
  takeLatest("@acervo/ANALISAR_KML", analisarKML),
  takeLatest("@CV_MATRICULA/BUSCAR_MATRICULA", verificarMatricula),
  takeLatest("@CV_MATRICULA/CRIAR_MATRICULA", criarMatricula),
]);
