import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addKMLComparacao } from "~/store/modules/acervo/actions";
import {
  limparBuscar,
  addImoveisFiltrados
} from "~/store/modules/mosaico/actions";

import "./style.scss";

const PageMenu = () => {
  $(function() {
    $('[data-toggle="toogle"]').tooltip();
  });
  let location = useLocation();
  const rota = location.pathname;

  const [toggleMenuLateral, setToggleMenuLateral] = useState(true);
  const dispatch = useDispatch();
  const [showCarot, setShowCarot] = useState(true);
  let $wrapper = $(".hk-wrapper");

  const MenuLateral = () => {
    if (toggleMenuLateral) {
      $wrapper.addClass("hk-nav-toggle");
    } else {
      $wrapper.removeClass("hk-nav-toggle");
      $(window).trigger("resize");
    }
  };

  useEffect(() => {
    if (rota !== "/mapa") {
      dispatch(limparBuscar("", true));
      dispatch(addImoveisFiltrados(false));
    }

    if (rota !== "/comparar") {
      dispatch(addKMLComparacao(false));
    }
  }, [rota]);

  useEffect(() => {
    if (rota.includes("detalhe")) {
      let $wrapper = $(".hk-wrapper");
      $wrapper.addClass("hk-nav-toggle");
    } else {
      $wrapper.removeClass("hk-nav-toggle");
      $(window).trigger("resize");
    }
  }, [rota]);

  useEffect(() => {
    let $wrapperNav = $(".hk-vertical-nav");
    let $iconMenu = $(".navbar-toggle-btn");
    let $menu = $(".hk-nav-light");
    $iconMenu.click(() => {
      const navToggle = $wrapperNav.hasClass("hk-nav-toggle");
      setShowCarot(navToggle);
    });
    $menu.mouseenter(() => {
      const navToggle = $wrapperNav.hasClass("hk-nav-toggle");
      if (navToggle) {
        setShowCarot(navToggle);
      }
    });
    $menu.mouseleave(() => {
      const navToggle = $wrapperNav.hasClass("hk-nav-toggle");
      if (navToggle) {
        setShowCarot(!navToggle);
      }
    });
  });
  return (
    <>
      <nav className="hk-nav hk-nav-light">
        <a
          href="mapa"
          id="hk_nav_close"
          className="hk-nav-close"
          onClick={e => {
            e.preventDefault();
            setToggleMenuLateral(!toggleMenuLateral);
            MenuLateral();
          }}
        >
          <span className="feather-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </span>
        </a>

        <div>
          <div className="navbar-nav-wrap">
            <ul className="navbar-nav flex-column">
              <li
                className={`nav-item ${
                  rota === "/" ? " active text-dark font-weight-500 font " : ""
                }`}
              >
                <Link to="/" className="nav-link ">
                  <i className="ion ion-ios-stats" />
                  <span className="nav-link-text">Meu Mosaico</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  rota === "/lista"
                    ? " active text-dark font-weight-500 font "
                    : ""
                }`}
              >
                <Link
                  to="/lista"
                  className="nav-link link-with-badge"
                  data-toggle="collapse"
                  data-target="#app_drp"
                >
                  <i className="fas fas-list" style={{ fontSize: "16px" }} />
                  <span className="nav-link-text">Imóveis</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  rota === "/mapa"
                    ? " active text-dark font-weight-500 font "
                    : ""
                }`}
              >
                <Link to="/mapa" className="nav-link" data-target="#auth_drp">
                  <i className="fas fas-map" style={{ fontSize: "16px" }} />
                  <span className="nav-link-text">Planta</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  rota === "/comparar"
                    ? " active text-dark font-weight-500 font "
                    : ""
                }`}
              >
                <Link
                  to="/comparar"
                  className="nav-link"
                  data-target="#auth_drp"
                >
                  <i className="fas fas-search" style={{ fontSize: "16px" }} />
                  <span className="nav-link-text">Comparar Imóvel</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default PageMenu;
